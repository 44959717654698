import React from "react"
import { graphql } from "gatsby"

import { renderAst } from "../utils/markdown.jsx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Max2xl, PX3, H1, H2, Max5xl } from "../components/blocks"
import DescribedPicture from "../components/described-picture"
import { PostIndex } from "../components/post"
import { Newsletter } from "../components/newsletter.jsx"
import moment from "moment"

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const index = data.index.nodes[0]

  const aktuelles = data.aktuelles.edges.map(x => {
    x.kind = "aktuelles"
    return x
  })

  const appointments = data.appointments.edges
    .map(x => {
      x.kind = "appointment"
      return x
    })
    .filter(post =>
      post.node.frontmatter.end
        ? moment().isSameOrBefore(post.node.frontmatter.end, "day")
        : moment().isSameOrBefore(post.node.frontmatter.start, "day")
    )

  let posts = [...appointments, ...aktuelles].filter(post =>
    post.node.frontmatter.onFrontpageUntil
      ? moment().isSameOrBefore(post.node.frontmatter.onFrontpageUntil, "day")
      : post.node.frontmatter.archiveAt
      ? moment().isBefore(post.node.frontmatter.archiveAt, "day")
      : true
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Startseite"
        description="Wir sind eine informelle Gruppe von Menschen, welche die Schüler*innen von Fridays For Future in ihrem Bemühen um Klimaschutz aktiv unterstützen wollen."
      />
      <Max5xl className="mt-3">
        <Max2xl>
          <PX3>
            <H1>{siteTitle}</H1>
          </PX3>
        </Max2xl>
      </Max5xl>

      {posts.length > 0 ? (
        <Max5xl className="my-3 pb-2">
          <Max2xl>
            <PX3>
              <H2>Aktuelles</H2>
              <PostIndex posts={posts} />
            </PX3>
          </Max2xl>
        </Max5xl>
      ) : (
        <></>
      )}


      <Max5xl className="tk-mt-3">
        <article>
          <Max2xl>
            <div className="pt-6 ">
              <PX3>{renderAst(index.htmlAst)}</PX3>
            </div>
          </Max2xl>
          <div className="py-3 sm:px-3">
            <DescribedPicture
              describedPicture={index.fields.described_picture}
            />
          </div>
        </article>
      </Max5xl>


      {index.frontmatter.newsletter_registration === "show" ? (
        <Newsletter />
      ) : (
        <></>
      )}
    </Layout>
  )
}

export const index = graphql`
  fragment Index on Query {
    index: allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "index" } } }
    ) {
      nodes {
        fields {
          slug
          described_picture {
            ...DescribedPicture
          }
        }
        frontmatter {
          title
          responsible_authors {
            firstname
          }
          newsletter_registration
          description
        }
        htmlAst
      }
    }
  }
`

export const pageQuery = graphql`
  query Startseite {
    site {
      siteMetadata {
        title
      }
    }
    ...Index

    ...CurrentAppointments

    aktuelles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/aktuelles/**/*.md" }
        frontmatter: { state: { eq: "current" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...PostIndexInformation
    }
  }
`
