import React from "react"
import { Max5xl, Max2xl, PX3, H2, P, ButtonLink } from "./blocks"

export const Newsletter = () => (
  <Max5xl className="my-3">
    <article>
      <Max2xl>
        <PX3>
          <H2>Newsletter</H2>
          <P className="py-3">
            Mit unserem Newsletter wollen wir über aktuelle Aktionen,
            Entwicklungen und andere interessante Themen zeitnah informieren.
          </P>
          <div className="pb-5">
            <ButtonLink className="py-3" href="http://eepurl.com/gwuLwn">
              Anmeldung für den Newsletter
            </ButtonLink>
          </div>
          {/* posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <div key={node.fields.slug}>
                  <h3 className="text-2xl">
                    <Link to={node.fields.slug}>{title}</Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </div>
              )
            }) */}
        </PX3>
      </Max2xl>
    </article>
    {/* <MCSignupForm /> */}
  </Max5xl>
)
